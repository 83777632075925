export const route = '/pools';

// const HomepageRoute: IRouteProps = {
//   path: route,
//   exact: true,
//   component: lazy(() => import('./Pools')),
//   name: 'Pools',
// };

// export default HomepageRoute;
